import { compare } from '@ember/utils';

export default function buildUnifiedEventTable(container, sourceFilter) {
  let remainingRawEvents = container?.rawEvents.slice() ?? [];
  const transportEvents = (container?.transportEvents.slice() ?? []).sort((a, b) => compare(b.timestamp, a.timestamp));
  const actualTransportEvents = transportEvents.filter(({ event }) => !event.includes('estimated'));
  const withoutDuplicates = peelOffDuplicates(actualTransportEvents);
  const currentTransportEvents = peelOffPreviousVersions(withoutDuplicates);
  const invalidatedResults = connectSuccessfulTransportEvents(
    remainingRawEvents.filter((event) => event.invalidatedAt),
    currentTransportEvents.filter((event) => event.invalidatedAt),
  );
  const actualResults = connectSuccessfulTransportEvents(
    [...remainingRawEvents.filter((event) => !event.invalidatedAt), ...invalidatedResults.remainingRawEvents],
    currentTransportEvents.filter((event) => !event.invalidatedAt),
  );
  const estimatedResults = connectSuccessfulTransportEvents(
    actualResults.remainingRawEvents,
    estimatedTransportEvents(transportEvents),
    true,
  );

  let events = [
    ...invalidatedResults.connectedTransportEvents,
    ...actualResults.connectedTransportEvents,
    ...estimatedResults.connectedTransportEvents,
    ...estimatedResults.remainingRawEvents,
  ];

  events = fixLfdTransportEvents(events);
  events = markAndFilterEstimatedTransportEvents(events);
  events = events.sort((a, b) =>
    compare(
      typeof b.timestamp === 'string' ? b.timestamp : b.timestamp?.toISOString(),
      typeof a.timestamp === 'string' ? a.timestamp : a.timestamp?.toISOString(),
    ),
  );

  const unifiedEvents = orderEventsAndGroupRawEvents(events);
  if (sourceFilter !== 'all') {
    return unifiedEvents.filter((event) => event.rawEvents.some((rawEvent) => rawEvent.dataSource === sourceFilter));
  }
  return unifiedEvents;
}

/* Helper methods */

const estimatedTransportEvents = (transportEvents) => {
  const events = [];
  const estimatedTransportEvents = transportEvents.filter(({ event }) => event.includes('estimated'));
  estimatedTransportEvents
    .sort((a, b) => compare(b.createdAt, a.createdAt))
    .forEach((event) => {
      if (!events.find((e) => e.locationLocode === event.locationLocode && e.event === event.event)) {
        events.push(event);
      }
    });
  return events;
};

const timestampsEqual = (date1, date2) => {
  const d1 = date1 instanceof Date ? date1 : new Date(date1);
  const d2 = date2 instanceof Date ? date2 : new Date(date2);
  return d1.getTime() === d2.getTime();
};

const shouldRawEventBeConnected = (rawEvent, transportEvent, estimated = false) => {
  try {
    if (rawEvent.id === transportEvent.sourceEvent?.id) {
      return true;
    }
  } catch (e) {
    // This error is ok and can be ignored.
    // Estimated transport events do not have sourceEvents, but sometimes (so far just in localhost, and just in certain situations) Ember Data throws an error when there is no sourceEvent
    console.log('Error in shouldRawEventBeConnected - likely because of a null sourceEvent');
  }

  const sameTimestamp = timestampsEqual(rawEvent.timestamp, transportEvent.timestamp);
  const sameLocation = rawEvent.locationLocode == transportEvent.locationLocode;
  if (estimated) {
    return rawEvent.possibleTransportEvents.includes(transportEvent.event) && sameLocation;
  } else {
    return rawEvent.transportEvent == transportEvent.event && (sameLocation || sameTimestamp);
  }
};

const connectSuccessfulTransportEvents = (rawEvents, transportEvents, estimated = false) => {
  let remainingRawEvents = rawEvents;
  const connectedTransportEvents = transportEvents
    .map((event) => {
      const rawEventsToConnect = remainingRawEvents.filter((rawEvent) =>
        shouldRawEventBeConnected(rawEvent, event, estimated),
      );
      rawEventsToConnect.forEach((rawEvent) => {
        if (rawEvent.id === event.sourceEvent?.id) {
          rawEvent.isSourceEvent = true;
        }
      });
      event.rawEvents = rawEventsToConnect.sort((a, b) => compare(a.createdAt, b.createdAt));
      remainingRawEvents = remainingRawEvents.filter((rawEvent) => {
        return !rawEventsToConnect.includes(rawEvent);
      });
      return event;
    })
    .compact();
  return {
    connectedTransportEvents,
    remainingRawEvents,
  };
};

const peelOffDuplicates = (transportEvents) => {
  // Certain transport events, such as 'container.pickup_lfd.changed', can have multiple previous versions that are NOT invalidated or tracked with `previousVersion`
  // We only want to show the latest version (per location), so we need to peel off those duplicates as well
  // Later, we may decide to fix this on the backend by automatically invalidating older events and setting `previousVersion`, but that would be a trickier task and would change what API customers see
  // If we fix it on the backend, and include a rake task to backfill previous shipments, we can remove this code
  // More discussion here: https://linear.app/terminal49/issue/DATA-4735/extra-last-free-day-transport-event#comment-0be3fe17
  const eventTypesToCheckForDuplicates = [
    'container.pickup_lfd.changed',
    'container.transport.estimated.arrived_at_inland_destination',
  ];
  let latestVersions = [];
  transportEvents.forEach((event) => {
    if (eventTypesToCheckForDuplicates.includes(event.event)) {
      const existingEvent = latestVersions.find(
        (e) => e.locationLocode === event.locationLocode && e.event === event.event,
      );
      if (!existingEvent) {
        latestVersions.push(event);
      } else if (compare(event.createdAt, existingEvent.createdAt) > 0) {
        latestVersions = latestVersions.filter((e) => e !== existingEvent);
        latestVersions.push(event);
      }
    }
  });
  return transportEvents.reject((event) => {
    return eventTypesToCheckForDuplicates.includes(event.event) && !latestVersions.includes(event);
  });
};

const peelOffPreviousVersions = (transportEvents) => {
  const previousVersionIds = transportEvents.map((event) => event.previousVersion?.id).compact();
  return transportEvents.filter((event) => !previousVersionIds.includes(event.id));
};

const fixLfdTransportEvents = (transportEvents) => {
  return transportEvents.map((event) => {
    if (event.event === 'container.pickup_lfd.changed') {
      const rawEvent = event.rawEvents[0];
      event.willOccurAt = rawEvent?.willOccurAt || rawEvent?.actualAt || 'n/a'; // this will show 'Invalid Date', which is better than an incorrect date
    }
    return event;
  });
};

const markAndFilterEstimatedTransportEvents = (transportEvents) => {
  return transportEvents
    .map((event) => {
      if (event.event.includes('estimated')) {
        event.estimated = true;

        if (event.rawEvents.length === 0) {
          // remove estimated transport events whose raw events have been matched with an actual transport event
          return null;
        }
      }
      return event;
    })
    .compact();
};

const createUnifiedEvent = (unaddedRawEvents) => {
  const firstEvent = unaddedRawEvents[0];
  const isEstimated = unaddedRawEvents.every((event) => event.estimated);
  const isMissed = firstEvent.eventType === 'missed';
  const isFloating = firstEvent.eventType === 'floating';
  const eventWithEstimatedTransportEvent = ['rail_unloaded', 'rail_arrived', 'arrived_at_inland_destination'].includes(
    firstEvent.event,
  );
  const isMissedBecauseEstimated = isEstimated && isMissed && !eventWithEstimatedTransportEvent;

  return {
    rawEvents: unaddedRawEvents.sort((a, b) => compare(a.createdAt, b.createdAt)),
    isRawEventsHeader: isFloating,
    isMissedCreationEventsHeader: isMissed,
    humanizedEventName: firstEvent.humanizedEventName,
    timestamp: firstEvent.timestamp,
    id: firstEvent.id, // needed for toggle expansion, so it doesn't toggle all placeholder unified events when one is clicked
    estimated: isEstimated,
    isMissedBecauseEstimated,
    locationName: isMissedBecauseEstimated ? firstEvent.locationName : undefined,
  };
};

const orderEventsAndGroupRawEvents = (events) => {
  const unifiedEvents = [];
  let unaddedRawEvents = [];

  events.forEach((event) => {
    event.eventType = determineEventType(event);

    const differentEventType = unaddedRawEvents.length && unaddedRawEvents[0].eventType !== event.eventType;
    const differentTransportEvent =
      unaddedRawEvents.length && event.eventType === 'missed' && unaddedRawEvents[0].event !== event.event;

    if (differentEventType || differentTransportEvent) {
      unifiedEvents.push(createUnifiedEvent(unaddedRawEvents));
      unaddedRawEvents = [];
    }

    if (event.isTransportEvent) {
      unifiedEvents.push(event);
    } else {
      unaddedRawEvents.push(event);
    }
  });
  if (unaddedRawEvents.length) {
    unifiedEvents.push(createUnifiedEvent(unaddedRawEvents));
  }
  return unifiedEvents;
};

const determineEventType = (event) => {
  if (event.isTransportEvent) return 'transport';
  if (event.transportEvent === 'raw') return 'floating';
  return 'missed';
};
