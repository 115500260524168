/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 * @param {TransportEventModel} event
 */

export default class CargoTransportEvent extends Component {
  get isTransportEvent() {
    return !this.args.event.isRawEventsHeader && !this.args.event.isMissedCreationEventsHeader;
  }
}
