import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEqual } from '@ember/utils';
import { filtersMap, type FilterValue } from 'harbor/utils/filters/filter-definitions';
//@ts-ignore - yet typed 🫠
import filterSerializers from 'harbor/utils/filters/filter-serializers';
import type ContainerViewShare from 'tnt-ui/models/container-view-share';
import type ContainerViewSummary from 'tnt-ui/models/container-view-summary';
import type SessionService from 'tnt-ui/services/session';
import { TrackedObject } from 'tracked-built-ins';

const groupOrders = { default: 0, import: 1, import_transfer: 2 } as Record<string, number>;

export interface ViewDefinition {
  filters: Record<string, any>;
  columns: string[];
  sort: string[];
  api_query: Record<string, any>;
  group_by?: string;
}

const HIDE_TEMPLATE_VIEWS = [
  'all-containers',
  'has-holds-not-discharged',
  'containers-empty-returned-in-the-last-24-hrs',
  'containers-picked-up-in-the-last-24-hrs',
  'per-diem-risk',
  'destination-picked-up',
  'awaiting-transfer',
];

export default class ContainerView extends Model {
  @service declare session: SessionService;

  // Volatile Attributes
  allContainerViewId: string = '6fe6c11c-9b1b-418c-82a8-2905eaf58920';
  @attr('boolean', { defaultValue: false }) declare isEdited: boolean;

  // Relationships
  @hasMany('container-view-share') declare containerViewShares: AsyncHasMany<ContainerViewShare>;
  @belongsTo('container-view-summary') declare containerViewSummary: AsyncBelongsTo<ContainerViewSummary>;
  @belongsTo('account', { async: false }) declare account: any;
  @belongsTo('user', { async: false }) declare user: any;

  // Attributes
  @attr('string', { defaultValue: 'ship' }) declare icon: string;
  @attr('string') declare title: string;
  @attr('string') declare identifier: string;
  @attr('string') declare description: string;
  @attr('string') declare viewType: 't49' | 'custom';
  @attr('boolean') declare isShared: boolean;
  @attr('boolean') declare isPublicShared: boolean;
  @attr('boolean') declare isAnonymized: boolean;
  @attr('string') declare createdBy: string;
  @attr('string') declare companyName: string;
  @attr() declare viewDefinition: ViewDefinition;
  @attr('string') declare featureFlag: string;
  @attr('number') declare order: number;
  @attr('string') declare group: string;
  @attr('boolean') declare addedByDefault: boolean;
  @attr('string') declare referenceId: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  //@ts-ignore
  @attr('tracked-object', {
    defaultValue: () =>
      new TrackedObject({
        showInDashboard: true,
        showTotalDemurrageFees: false,
        showTotalFees: false,
        showTotalOnHold: false,
        showTotalRequiresAttention: false,
      }),
  })
  declare settings: {
    showInDashboard: boolean;
    showTotalDemurrageFees: boolean;
    showTotalFees: boolean;
    showTotalOnHold: boolean;
    showTotalRequiresAttention: boolean;
  };

  // Total number of settings enabled
  get totalSettingsEnabled() {
    return Object.values(this.settings).filter((v) => v).length - 1;
  }

  get isMine() {
    return isEqual(this.user?.id, this.session.currentUser?.id);
  }

  get isTemplate() {
    return isEqual(this.viewType, 't49');
  }

  get isNotHidden() {
    return !HIDE_TEMPLATE_VIEWS.includes(this.identifier);
  }

  get isAll() {
    return isEqual(this.referenceId, this.allContainerViewId);
  }

  get isNotAll() {
    return !this.isAll;
  }

  get groupOrder() {
    return groupOrders[this.group];
  }

  get queryParamsPOJO(): Record<string, any> {
    const containerViewFilters = this.viewDefinition?.filters || {};
    return Object.keys(containerViewFilters).reduce((acc: any, queryParam) => {
      const filterDefinition = filtersMap.get(queryParam);
      const queryParamValue = containerViewFilters[queryParam];

      if (!filterDefinition || queryParamValue === null || queryParamValue === undefined) {
        return acc;
      }

      const filterSerializer = filterSerializers[filterDefinition.type];
      acc[queryParam] = filterSerializer.from_url(queryParamValue);
      acc[queryParam] = filterSerializer.to_url(acc[queryParam]);

      return acc;
    }, {});
  }

  get url() {
    const { filters, sort, group_by } = this.viewDefinition;

    const filterMapValues = Array.from(filtersMap.values());
    const arrayFilters = filterMapValues
      .filter((v: FilterValue) => Array.isArray(v.defaultValue))
      .map((v: FilterValue) => v.queryParam);
    const combinedFilters = Object.assign(
      {},
      Object.fromEntries(filterMapValues.map((v: FilterValue) => [v.queryParam, v.defaultValue])),
      filters,
    );
    const encodedFilters = Object.fromEntries(
      Object.entries(combinedFilters)
        .filter(([_, value]) => ![null, undefined].includes(value))
        .map(([key, value]) => {
          if (['true', 'false'].includes(value)) {
            return [key, value === 'true'];
          }

          if (Array.isArray(value)) {
            return [key, JSON.stringify(value || [])];
          }

          if (arrayFilters.includes(key)) {
            return [key, `"${value}"`];
          }

          return [key, value];
        }),
    );

    const encodedSort = JSON.stringify(sort || []);

    const queryParams = new URLSearchParams({
      ...encodedFilters,
      sort: encodedSort,
      search: '',
    });

    if (group_by) {
      queryParams.append('groupBy', group_by);
    }

    return `/containers/new/${this.id}?${queryParams.toString()}`;
  }

  save(): any {
    if (!this.isDeleted) {
      this.isEdited = false;
    }
    return super.save();
  }

  push() {
    const adapter = this.store.adapterFor('container-view' as never) as any;
    return adapter.push(this.id);
  }

  pull() {
    const adapter = this.store.adapterFor('container-view' as never) as any;
    return adapter.pull(this.id);
  }

  async clone() {
    const adapter = this.store.adapterFor('container-view' as never) as any;
    const count = this.store
      .peekAll('container-view')
      .filter(({ isTemplate, title }) => !isTemplate && title.startsWith(this.title)).length;
    const payload = await adapter.clone(this, count);
    this.store.pushPayload(payload);
    return payload;
  }

  toJSON(): any {
    return {
      icon: this.icon,
      title: this.title,
      identifier: this.identifier,
      viewType: 'custom',
      companyName: this.companyName,
      viewDefinition: this.viewDefinition,
      featureFlag: this.featureFlag,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      account: this.account,
      user: this.user,
      isEdited: false,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'container-view': ContainerView;
  }
}
