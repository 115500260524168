/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class CargoUnifiedEvent extends Component {
  @service router;
  @service session;

  get outerClasses() {
    const classes = ['tw-border-l-4 tw-rounded-md'];
    if (this.args.event.isMissedBecauseEstimated) {
      classes.push('tw-bg-gray-50');
    } else if (this.args.event.isMissedCreationEventsHeader) {
      classes.push('tw-border-yellow-500 tw-bg-gray-100');
    } else if (this.args.event.isRawEventsHeader) {
      classes.push('tw-border-blue-500');
    } else {
      classes.push('tw-border-green-500');
    }
    return classes.join(' ');
  }

  get showInvalidateButton() {
    return this.isAdmin && this.isAcceptableRoute;
  }

  get showEditButton() {
    return this.isAdmin && this.isAcceptableRoute;
  }

  get isAdmin() {
    return this.session.currentUser?.isAdmin;
  }

  get isAcceptableRoute() {
    const currentRoute = this.router.currentRoute;
    const acceptableRoutes = ['shipments.details'];
    return acceptableRoutes.includes(currentRoute?.name);
  }
}
