/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment-timezone';
import { rawEventMappings } from '../../../utils/event-name-mappings';

const DEBOUNCE = 300;
export default class EventFormModal extends Component {
  @service store;
  @service fetch;
  @service router;
  @service notify;

  @tracked event;
  @tracked newTimestamp;
  @tracked error;

  constructor() {
    super(...arguments);

    if (this.args.type === 'edit') {
      this.event = this.args.event;
    } else {
      this.event = this.store.createRecord('raw-event', {
        ...this.args.event,
      });
    }
    this.newTimestamp = this.args.event.timestamp || new Date();
  }

  get eventOptions() {
    return Object.entries(rawEventMappings).map(([key, value]) => ({
      label: value.name,
      value: key,
    }));
  }

  get isVesselEvent() {
    const vesselEventTypes = [
      'vessel_loaded',
      'vessel_departed',
      'vessel_arrived',
      'vessel_berthed',
      'vessel_discharged',
      'transshipment_arrived',
      'transshipment_departed',
      'transshipment_loaded',
      'transshipment_discharged',
      'carrier_release',
      'positioned_in',
      'positioned_out',
      // should feeder events be included?
    ];
    return vesselEventTypes.includes(this.event.event);
  }

  fetchLocations = task({ restartable: true }, async (searchText) => {
    await timeout(DEBOUNCE);
    const locations = await this.store.query('port', {
      q: searchText,
      type: this.event.event,
      isSearch: true,
    });
    return locations.slice();
  });

  fetchVessels = task({ restartable: true }, async (searchText) => {
    await timeout(DEBOUNCE);
    const vessels = await this.store.query('vessel', {
      q: searchText,
      isSearch: true,
    });
    return vessels.slice();
  });

  @action
  updateEvent(key, property) {
    this.event[key] = property.value || property.target?.value || property;
    if (key === 'location') {
      this.setTimestampBasedOnTimeZone();
    }
  }

  async refreshCurrentRoute() {
    const currentRoute = this.router.currentRouteName;
    await this.router.refresh(currentRoute);
  }

  get timezone() {
    return this.event.location?.timeZone;
  }

  setTimestampBasedOnTimeZone() {
    let timestamp = this.newTimestamp;

    if (this.timezone) {
      let timeString = moment(timestamp).format('YYYY-MM-DDTHH:mm');

      timestamp = moment.tz(timeString, this.timezone).toDate();
    }

    this.event[this.newTimestampKey] = timestamp;
  }

  @action
  setTimestamp(params) {
    this.newTimestamp = params[0];
    this.setTimestampBasedOnTimeZone();
  }

  get newTimestampKey() {
    if (this.event.event === 'last_free_day_changed') {
      return 'willOccurAt';
    }
    return 'actualAt';
  }

  saveEvent = task({ restartable: false }, async () => {
    this.error = null;
    this.event.container = this.args.container;
    this.event.timezone = this.timezone;

    try {
      await this.event.save();
      await this.refreshCurrentRoute();

      const successMessage = this.args.type === 'create' ? 'Event created successfully' : 'Event updated successfully';
      this.notify.success(successMessage);
      this.args.onClose();
    } catch (error) {
      const errorMessage =
        this.args.type === 'create' ? 'There was an error creating the event' : 'There was an error updating the event';
      this.error = errorMessage;
      this.notify.error(errorMessage);
    }
  });

  get altFormat() {
    const timezone = this.timezone || 'UTC';
    return `M j, Y h:i K \\${timezone
      .split('')
      .map((c) => `\\${c}`)
      .join('')}`;
  }
}
