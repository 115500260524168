/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

interface Args {
  dataSource: string;
  classes?: string;
}

export default class CargoSourceIconComponent extends Component<Args> {
  get iconClass(): string | undefined {
    const mapping: Record<string, string> = {
      shipping_line: 'fa-regular fa-ship',
      rail: 'fa-regular fa-train',
      terminal: 'fa-regular fa-warehouse',
      t49_operations_team: 'fa-regular fa-pen-to-square',
    };
    return mapping[this.args.dataSource];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cargo::SourceIcon': typeof CargoSourceIconComponent;
  }
}
